import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class MailchimpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listFields: {
        FNAME: '',
        EMAIL: '',
        STATUS: '',
      },
    }
  }

  _handleSubmit = async e => {
    e.preventDefault()
    const { listFields } = this.state
    const result = await addToMailchimp(listFields.EMAIL, listFields)

    this.setState({
      listFields: {
        STATUS: result.msg,
      },
    })
  }

  _hangleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => ({
      listFields: {
        ...prevState.listFields,
        [name]: value,
      },
    }))
  }

  render() {
    const { listFields } = this.state
    return (
      <>
        {!listFields.STATUS ? (
          <form
            onSubmit={this._handleSubmit}
            className="bg-green text-center p-4 rounded"
          >
            <h4 className="h6 text-uppercase font-weight-bold mb-1">
              Get positive news straight to your inbox
            </h4>
            <p className="small text-uppercase font-weight-bold mb-1">
              Subscribe to our newsletter
            </p>
            <div className="row">
              <div className="col-lg-6 mt-2 mt-lg-3">
                <input
                  type="text"
                  className="form-control border-0"
                  name="FNAME"
                  value={listFields.FNAME}
                  placeholder="Name"
                  onChange={this._hangleChange}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2 mt-lg-3">
                <input
                  type="email"
                  className="form-control border-0"
                  name="EMAIL"
                  value={listFields.EMAIL}
                  placeholder="Email"
                  onChange={this._hangleChange}
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-sm btn-outline-warning mt-4"
            >
              Subscribe
            </button>
          </form>
        ) : (
          <div className="border border-primary text-center p-4 rounded">
            <h5
              className="text-center text-white"
              dangerouslySetInnerHTML={{ __html: listFields.STATUS }}
            />
          </div>
        )}
      </>
    )
  }
}
