import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { element } from 'prop-types'
import SEO from '../components/seo'
import Layout from '../components/layout'
import MailchimpForm from '../components/mailchimpForm'

const PostLayout = ({
  className,
  image,
  title,
  text,
  category,
  path,
  small,
}) => (
  <div
    className={`${className ? `${className} ` : ''}bg-secondary post-hover${
      small ? '' : ' post-h-100'
    }`}
  >
    <Link to={path} className="position-relative d-block">
      <small className="font-weight-bold bg-warning text-uppercase px-3 py-1 text-white position-absolute bottom-0 z-index-1">
        {category}
      </small>
      {image && (
        <Img
        fluid={image.localFile.childImageSharp.fluid}
          alt={image.title}
          className={`img-fluid d-block mx-auto${
            small ? ' post-small-image' : ' post-large-image'
          }`}
        />
      )}
    </Link>
    <div className={`${small ? ' p-3' : ' p-4'}`}>
      <article className="h5 font-weight-normal m-0">
        <h2
          className={`clearfix${
            small ? ' h6 mb-1' : ' h4 mb-4'
          } pb-2 font-weight-bold text-capitalize`}
        >
          <u>
            <Link
              to={path}
              className="text-white"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </u>
        </h2>
        <p
          className={`${
            small ? 'small' : ''
          } h5 font-weight-normal p-0 m-0 post-line-height`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </article>
    </div>
  </div>
)

const PostRowLayout = ({ watchId, readId, listenId, posts, index }) => {
  const MAX_LENGTH_330 = 330
  const MAX_LENGTH_85 = 85

  const watchPost = posts.nodes.find(({ wordpress_id, acf }) => {
    if (!acf.watch_read_listen_post) return false
    const { watch_read_listen_post: post } = acf
    return (
      post[0].__typename === 'WordPressAcf_watch' && wordpress_id === watchId
    )
  })

  const readPost = posts.nodes.find(({ wordpress_id, acf }) => {
    if (!acf.watch_read_listen_post) return false
    const { watch_read_listen_post: post } = acf
    return post[0].__typename === 'WordPressAcf_read' && wordpress_id === readId
  })
  const listenPost = posts.nodes.find(({ wordpress_id, acf }) => {
    if (!acf.watch_read_listen_post) return false
    const { watch_read_listen_post: post } = acf
    return (
      post[0].__typename === 'WordPressAcf_listen' && wordpress_id === listenId
    )
  })
  const getWatchPost = post => {
    const { wordpress_id, title, path, acf } = post
    const { watch_read_listen_post: p } = acf
    return {
      wordpress_id,
      title,
      description: p[0].editor || p[0].description,
      image: p[0].image,
      playlist_id: p[0].playlist_id,
      path,
    }
  }

  if (!watchPost || !readPost || !listenPost) return <></>

  const watch = getWatchPost(watchPost)
  const read = getWatchPost(readPost)
  const listen = getWatchPost(listenPost)

  return (
    <>
      <div className="row mt-4">
        {index % 2 == 0 && (
          <div className="col-md-7 col-lg-8">
            <PostLayout
              className="mt-4 mt-md-0"
              title={watch.title}
              text={`${watch.description.substring(0, MAX_LENGTH_330)}...`}
              image={watch.image}
              path={`/video/${watch.playlist_id}`}
              category="Watch"
            />
          </div>
        )}

        {index % 2 == 0 && (
          <div className="col-md-5 col-lg-4">
            <PostLayout
              className="mt-4 mt-md-0"
              title={read.title}
              text={`${read.description.substring(0, MAX_LENGTH_85)}...`}
              image={read.image}
              path={read.path}
              category="Read"
              small
            />
            <PostLayout
              className="mt-4"
              title={listen.title}
              text={`${listen.description.substring(0, MAX_LENGTH_85)}...`}
              image={listen.image}
              category="Listen"
              path={listen.path}
              small
            />
          </div>
        )}

        {index % 2 != 0 && (
          <div className="col-md-5 col-lg-4 order-2 order-md-1">
            <PostLayout
              className="mt-4 mt-md-0"
              title={read.title}
              text={`${read.description.substring(0, MAX_LENGTH_85)}...`}
              image={read.image}
              category="Read"
              path={read.path}
              small
            />
            <PostLayout
              className="mt-4"
              title={listen.title}
              text={`${listen.description.substring(0, MAX_LENGTH_85)}...`}
              image={listen.image}
              category="Listen"
              path={listen.path}
              small
            />
          </div>
        )}

        {index % 2 != 0 && (
          <div className="col-md-7 col-lg-8 order-1 order-md-2">
            <PostLayout
              className="mt-4 mt-md-0"
              title={watch.title}
              text={`${watch.description.substring(0, MAX_LENGTH_330)}...`}
              image={watch.image}
              path={`/video/${watch.playlist_id}`}
              category="Watch"
            />
          </div>
        )}
      </div>
      <hr className="d-block border-white mt-4 mb-0" />
    </>
  )
}

const Covid19Template = ({ data }) => {
  const StaticPage = data.wordpressPage
  const { title, content, acf } = StaticPage
  const { covid19 } = acf
  const { news, repeater } = covid19
  const posts = data.allWordpressPost

  return (
    <Layout>
      <SEO title={title} />
      <section className="bg-dark text-white py-5">
        <div className="container">
          <h1 className="display-4 font-weight-bold mb-3 text-center">
            {title}
          </h1>
          <div
            className="h5 text-center"
            dangerouslySetInnerHTML={{ __html: content }}
          />

          {repeater.map((post_group, index) => {
            const { group } = post_group
            const { watch_id, read_id, listen_id } = group
            return (
              <React.Fragment key={index}>
                <PostRowLayout
                  watchId={watch_id}
                  readId={read_id}
                  listenId={listen_id}
                  posts={posts}
                  index={index}
                />
                {index === 0 && (
                  <>
                    <div className="row">
                      <div className="col-md mt-3">
                        <h3 className="h5 mb-3 text-uppercase font-weight-bold">
                          Positive News Stories
                        </h3>
                        <ul className="list-unstyled d-flex flex-column p-0">
                          {news.map((new_source, index) => (
                            <li
                              className="position-relative d-block mb-2 ul-link-item"
                              key={index}
                            >
                              <u>
                                <a
                                  href={new_source.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-primary"
                                >
                                  {new_source.title}
                                </a>
                              </u>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-md mb-3 my-md-auto">
                        <MailchimpForm />
                      </div>
                    </div>
                    <hr className="d-block border-white my-0" />
                  </>
                )}
              </React.Fragment>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Covid19Template
export const pageQuery = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      acf {
        covid19 {
          repeater {
            group {
              watch_id: watch_post
              read_id: read_post
              listen_id: listen_post
            }
          }
          news {
            title
            link
          }
        }
      }
    }
    allWordpressPost {
      nodes {
        title
        path
        acf {
          watch_read_listen_post {
            __typename
            ... on WordPressAcf_watch {
              id
              playlist_id
              name
              subject
              description
              image {
                title
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            ... on WordPressAcf_read {
              editor
              image {
                title
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            ... on WordPressAcf_listen {
              editor
              image {
                title
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        wordpress_id
      }
    }
  }
`
